import React, {useState, useContext, useRef, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import QuestionAttemptLive from './QuestionAttemptLive.js'
import { Link, useLocation } from 'react-router-dom';
import QuestionResponse from "./QuestionResponse.js";
import NextButtonLive from "./NextButtonLive.js";
import useExitPrompt from './useExitPrompt.js'
import {getNextQuestion} from './services/list.js'
import { SocketContext } from "./App.js";
import LiveScoreBoard from "./LiveScoreBoard.js";
import ChatPageTailwind from "./chat/ChatPageTailwind.js";
import { Button, TextInput } from "flowbite-react";


export default function QuizAttempLive() {
  const user = useSelector((state) => state.user.value) 
  
  const dispatch = useDispatch()  
    
    const [question, setQuestion] = useState(null) 
    const [question_number, setQuestionNumber] = useState(1)
    const [showQuestion, setShowQuestion] = useState(false)
    const [showAttemptResponse, setShowQuestionAttemptResponse] = useState(false)
    const [attemptResponse, setAttemptResponse] = useState(null)
    const [myNextButtonFlag, setMyNextButtonFlag] = useState(false)
    //const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);
    const mounted = useRef(true);
    const socket = useContext(SocketContext);
   
    const button_ref = useRef()

    const currentLocation = useLocation()
  const arr = currentLocation.pathname.split('/')
  //const question_number = arr[arr.length-1]
  const quizId = arr[arr.length-1]

  useEffect(() => {
    socket.on('enable_next_button', (arg, callback) => {
       //console.log(" QuizAttempt got enable_next_button_ message arg=", arg)
        callback({status: "I got enable_next_button message. OK", user_name: user.user_name});
        if (arg.destination.indexOf("except") >= 0) {
          if (arg.target_student !== user.user_name) {
            setMyNextButtonFlag(true)
          }
        }
        else if (arg.destination.indexOf('everybody') >= 0 ) { 
          setMyNextButtonFlag(true)
        }
        else if (arg.destination.indexOf('only') >= 0 ) {
          if (arg.target_student === user.user_name) {
            setMyNextButtonFlag(true)
          }
        }
        
    })
    return () => {
      socket.off("enable_next_button")
  }   
  },[socket, dispatch, user.user_name])
  
  /*
  //NOTE: this similar to componentWillUnmount()
  /*
  useEffect(() => {
    return () => {
      setShowExitPrompt(false)
    }
    //eslint-disable-next-line
  }, [])
*/
    const setTheNextQuestion = (value) => {
        setQuestion(value)
        //setCurrentQuestionNumber(value.question_number)
        setShowQuestionAttemptResponse(false)
    }
    const setTheAttemptResponse = (value) => {
      //this function is called in QuestionAttempt after it finishes
      //processing the question attempt and a response is returned
      setAttemptResponse(value)
      setShowQuestionAttemptResponse(true)
   }

    const getQuestion = (value) => {
      getNextQuestion(quizId, question_number)
      .then((response) => {
        console.log(response.data)
          setTheNextQuestion(response.data.question)
          setShowQuestion(true)     
          setMyNextButtonFlag(false)  
          const params = {
               user_name: user.user_name,
               question_number: question_number
          }
          socket.emit("next_question_fetched", params)
          button_ref.current.disabled = true
          button_ref.current.style.backgroundColor = "gray";
          button_ref.current.innerText = "Working on ...";
      })
      .catch(error => {
          console.log(error)
      });
    }

    const setShowQuestionFlag = (value) => {
       setShowQuestion(value)
    }

    const resetNextButtonFlag = () => {
      //this function is called in NextButton upon a question_attempt_id is created
      //from the server
      //console.log("calling reset ...")
       setMyNextButtonFlag(false)
    }

    return ( 
        <>
       
        <div style={{marginTop:"20px", marginLeft:"80px", marginRight:"50px"}}>
        
         <div><span className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
          <Link to='/' >Home</Link></span><span className="text-red-600">&nbsp;&nbsp;{user.user_name}</span>
         </div>
         
         <br />
         <div className="flex flex-row justify-center">
            <Button ref = {button_ref} onClick={(e) => getQuestion(e.target)}>Start Quiz</Button>
         
            <span className="px-3">Quiz id: <span className="text-orange-600 text-lg">{quizId}</span></span>
            <span className='flex flex-row bg-amber-500'>Question Number:
                  <TextInput type='text' value={question_number} size='auto' 
                    onChange={(e) => setQuestionNumber(e.target.value)}/>
                    
                  </span>
           
         </div>
         <div className="flex flex-row gap-2 bg-slate-200 justify-between">
         <div className=" w-full">
         {(showQuestion) ?
           <QuestionAttemptLive 
           question={question} 
           setShowQuestion={setShowQuestionFlag}
           setAttemptResponse={setTheAttemptResponse}
           />
          :
          <div className="w-full">
            {showAttemptResponse && <QuestionResponse question={question} response_content={attemptResponse} />}
            { myNextButtonFlag &&
                <NextButtonLive quiz_id={quizId} 
                next_question_number={question.question_number + 1} 
                setNextQuestion={setTheNextQuestion}
                setShowQuestion={setShowQuestionFlag}
                resetNextButtonFlag = {resetNextButtonFlag}
                />
            }
          </div>
        }
         </div>
         { user.role === 'student' && 
         <div className="bg-green-200">
            <span><LiveScoreBoard class_id={user.classId} question_number = {question_number} /></span>
         </div>
          }
      </div>
      <div><ChatPageTailwind layout = "flex_column"/></div>
      { user.role === 'teacher' && 
         <div className="bg-amber-200">
            <span><LiveScoreBoard class_id={user.classId} /></span>
         </div>
          }
      </div>
       </>
    )
    
}

