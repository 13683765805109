import React from 'react'
import { GoCheckbox } from "react-icons/go";
import { RxCross2 } from "react-icons/rx";

export function ScoreDisplay({errorFlag, total_score, minutes, seconds }) {
    
    return (
        <>
                <div className='flex flex-col mb-2 mt-1'>
                  { errorFlag ?
                  <div className='flex justify-center'>
                   <RxCross2 className='text-red-500  text-3xl' />
                   <RxCross2 className='text-red-500  text-3xl' />
                   <RxCross2 className='text-red-500  text-3xl' />
                   </div>
                   :
                   <div className='flex justify-center'>
                    <GoCheckbox className='text-green-600 text-3xl'/>
                    <GoCheckbox className='text-green-600 text-3xl'/>
                    <GoCheckbox className='text-green-600 text-3xl '/>
                    </div>
                  }
                  <div className='m-2 mb-1 text-md'>Score: <span className='text-red-700 text-lg'>{total_score}</span>
                  </div>
                  <div className='mx-2 mb-1 text-md'>Elapsed Time:</div>
                  <div>
                    { (minutes > 0) && 
                      <span className='ml-4'><span className='text-red-700 text-lg'>{minutes}</span> minutes </span>
                    }
                    <span className='mx-2'><span className='text-red-700 text-lg'>{seconds}</span> seconds.</span>
                  </div>
                  </div>
        </>
    )
}
