    export default function format_button_cloze_question_content(content) {
        var regExp = /\[.*?\]/g
        var matches = content.match(regExp);
        //there should be only one match for ButtonSelectClozeQuestion
        let words = matches[0].replace('[','').replace(']','')

        console.log("UUUUUQQQQQQQQQQQQQQQQQQQQQQ",content)
        // this is [a/an/many] book.
        var temp_sentence_with_star =  content.replace(/ *\[[^\]]*]/g, ' * ');
        // this is * book.
        console.log("MMM", temp_sentence_with_star)
        let left_and_right_parts = temp_sentence_with_star.split('*')
        console.log(left_and_right_parts)
        /*
        let cloze_question_form = ''
         let isDropdown = false;
        var regExp = /\[.*?\]/g
        var matches = question.content.match(regExp);
             //matches =  [ '[^am/is]', '[^was/were]' ]
             let length_of_longest_word = 1;
             //console.log("leng of longest word"+length_of_longest_word)

        for (var i = 0; i < matches.length; i++) {
            if (matches[i].length > length_of_longest_word) {
                    length_of_longest_word = matches[i].length
            }
            var str = matches[i];
            if (str.indexOf('/') >= 0 ) {
                isDropdown = true;
            }
        }
        //console.log("leng of longest word"+length_of_longest_word)
         // pound signs (#) denote new lines
         var content_with_new_lines = question.content.replace(/#/g,'<br><br>')
         //replace bracket contents with '*'
         var temp_sentence_with_stars = question.content.replace(/ *\[[^\]]*]/g, ' * ');
         var sentence_parts = temp_sentence_with_stars.split('*');

         console.log("YYYYYYY", sentence_parts)
         */
         //return "OK"
         return {left: left_and_right_parts[0], 
            right: left_and_right_parts[1],
            words: words
         }
}