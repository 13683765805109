import React, {useState, useRef, useEffect} from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Login from './auth/Login'
import Logout from './auth/Logout';
import Home from './Home';
import Subcategory from './Subcategory';
import Unit from './UnitStudent';
import QuizAttempt from './QuizAttempt';
import QuizAttemptLive from './QuizAttemptLive';

import {getIds, getCategories } from './services/list';

import io from "socket.io-client";
import Games from './Games';
import { MatchGame } from './MatchGame';

import { QuizAttemptsManager } from './admin/QuizAttemptsManager';
import { CategoriesManager } from './admin/CategoriesManager';
import { UnitsManager } from './admin/UnitsManager';
import { QuizzesManager } from './admin/QuizzesManager';
import { QuestionsManager } from './admin/QuestionsManager';
import { NewQuestion } from './admin/new_question/NewQuestion';
import FileUploaderS3 from './utils/FileUploaderS3';
import { DndContext } from '@dnd-kit/core';

import { SpeechConfig } from 'microsoft-cognitiveservices-speech-sdk';

import AWS from 'aws-sdk'
/*
 const str = 'Aditi |Amy | Astrid | Bianca | Brian | Camila | Carla | Carmen | Celine | Chantal | Conchita | Cristiano | Dora | Emma | Enrique | Ewa | Filiz | Gabrielle | Geraint | Giorgio | Gwyneth | Hans | Ines | Ivy | Jacek | Jan | Joanna | Joey | Justin | Karl | Kendra | Kevin | Kimberly | Lea | Liv | Lotte | Lucia | Lupe | Mads | Maja | Marlene | Mathieu | Matthew | Maxim | Mia | Miguel | Mizuki | Naja | Nicole | Olivia | Penelope | Raveena | Ricardo | Ruben | Russell | Salli | Seoyeon | Takumi | Tatyana | Vicki | Vitoria | Zeina | Zhiyu | Aria | Ayanda | Arlet | Hannah | Arthur | Daniel | Liam | Pedro | Kajal | Hiujin | Laura | Elin | Ida | Suvi | Ola | Hala | Andres | Sergio | Remi | Adriano | Thiago | Ruth | Stephen | Kazuha | Tomoko | Niamh | Sofie | Lisa | Isabelle | Zayd | Danielle | Gregory | Burcu'
 //console.log(str.split('|'))
 const mlist = str.split('|').map((str) => {
     const name = str.toLowerCase().trim()
     const s = `case "${name}": return .${name}`
     return s
 })
 console.log(mlist)
 */


function setAuth(userToken) {
  //console.log(JSON.stringify({ x: 5, y: 6 }));
  //console.log("in setTToken JSON.stringy userToken ="+JSON.stringify(userToken))
  //console.log("MMMMM", userToken)
  //console.log("MMMMSSSSSM", JSON.stringify(userToken))
  sessionStorage.setItem('auth', JSON.stringify(userToken));
}
function getAuth() {
  const tokenString = sessionStorage.getItem('auth');
  //console.log(" in getAuth tokenString from session Storage ="+tokenString)
  const userToken = JSON.parse(tokenString);
  //console.log("in getAuth userTOken after parsing", userToken)
  return userToken?.auth
  //return userToken
}
export const SocketContext = React.createContext();
const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:5001';
//the following code DOES NOT make a connection. It just prevents
//an immediate connection
const socket = io.connect(URL, {
   autoConnect: false
});


AWS.config.update ({
  //accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  //secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
})

const polly = new AWS.Polly()

const azureSpeechConfig = SpeechConfig.fromSubscription('0a1e83a35c7642c49b193de23611e07f', 'eastus');


//console.log("REGION, ",process.env.REACT_APP_AWS_REGION )

//AWS.config.region = 'process.env.REACT_APP_AWS_REGION';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId: 'us-east-1:3566d757-23f9-412f-9495-bcfd46a4ff58'});

//const polly = new AWS.Polly()

export const PollyContext = React.createContext();
export const AzureSpeechContext = React.createContext();

export function App(props) {
  const [token, setToken] = useState()
  const [subCategoryIds, setSubCategoryIds] = useState([])
  const [unitIds, setUnitIds] = useState([])
  const mounted = useRef(true);

  const auth = getAuth();
  
    //const [speechKey, setSpeechKey] = useState('0a1e83a35c7642c49b193de23611e07f');
    //const [speechRegion, setSpeechRegion] = useState('eastus'); 


useEffect(() => {
  mounted.current = true;
  if (!auth) return
  getIds()
  .then((response) => {
    //console.log("NNNNNNNN response.data", response.data)
      setSubCategoryIds(response.data.sub_category_ids)
      setUnitIds(response.data.unit_ids)
  })
  return () => mounted.current = false;
},[auth])

  if(!auth) { 
    return <Login setToken={setToken} setAuth={setAuth} />
  }
  return (
    <>
        <SocketContext.Provider value={socket}>
         <PollyContext.Provider value={polly}>
         <AzureSpeechContext.Provider value ={azureSpeechConfig}>
         <DndContext>
          <BrowserRouter>
            <Routes>
              <Route path="/" element = {<Home socket={socket}/>} />
              <Route path="/logout" element = {<Logout setToken={setToken} setAuth = {setAuth} />} />
              { subCategoryIds.map(subcat_id => (
                <>
                  <Route key={subcat_id.id} path={`/sub_categories/${subcat_id.id}`} element={<Subcategory subcat_id = {subcat_id.id} />} />
                  <Route key={subcat_id.id} path={`/sub_categories/manage_units/${subcat_id.id}`} element={<UnitsManager subcat_id = {subcat_id.id} />} />
                  </>
                  ))
              }
              { unitIds.map(unit_id => (
                <>
                  <Route key={unit_id} path={`/units/${unit_id}`} element={<Unit it = {unit_id} />} />
                  <Route key={unit_id} path={`/units/manage_quizzes/${unit_id}`} element={<QuizzesManager unit_id = {unit_id} />} />
                </>
                  ))
              }

              <Route path={`/quiz_attempts/take_quiz/:quiz_id/`} element={<QuizAttempt />} />
              <Route path={`/quiz_attempts/take_live_quiz/:quiz_id`} element={<QuizAttemptLive />} />
              <Route path={`/questions/create/:quiz_id/:format`} element={<NewQuestion />} />
              <Route path={`/quizzes/manage_questions/:quiz_id`} element={<QuestionsManager  />} />
              <Route path={`/manage_categories`} element={<CategoriesManager  />} />
              <Route path={`/manage_quiz_attempts`} element={<QuizAttemptsManager />} />
              <Route path="/matching_games" element = {<Games />} />
              <Route path={`/matching_games/play/:id`} element={<MatchGame />} />
              <Route path={`/upload_s3`} element={<FileUploaderS3 />} />
            </Routes>
          </BrowserRouter>
          </DndContext>
          </AzureSpeechContext.Provider>
          </PollyContext.Provider>
        </SocketContext.Provider>
    </>
  )
}
export default App;