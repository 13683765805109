import React, {useState, useEffect} from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import SubmitButton from './SubmitButton'
import { Button } from 'flowbite-react'

export function SpeechRecognitionQA({question, setUserAnswer}) {
    //const [isListening, setIsListening] = useState(false)
    //const [isMicBlocked, setIsMicBlocked] = useState(false)
    const [questioncontent, setQuestionContent] = useState([])

    useEffect (() => {
        const arr = question.content.split('#')
        
        setQuestionContent( arr )
    },[question.content])
    
    const handleClick = () => {
        if (transcript.length === 0) {
            alert("Please answer")
            return
        }
        setUserAnswer(transcript)
    }

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    
    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

   /*
 {
            studentsList.map((student, index) =>
             <li style={{color:"yellow"}} key={ index }>{ student.username }, {student.id }</li>
             )
         }
   */

    return (
        <>
            <p>&nbsp;</p>
            {
                questioncontent.map((sentence, index) => 
                    <p style={{ lineHeight:"0.8"}} key={ index }>{sentence}</p>
                )
            }
  
            <div>
      {listening ? <p>Listening ...</p> : <p>&nbsp;</p>}
      <div className='flex flex-row'>
      <Button className='mx-1 bg-amber-700' onClick={SpeechRecognition.startListening} disabled={listening}>Start</Button>
      <Button className='mx-1' onClick={SpeechRecognition.stopListening} disabled={!listening}>Stop</Button>
      <Button className='mx-1 bg-blue-600' onClick={resetTranscript}>Reset</Button>
      </div>
      <br />
      <p>&nbsp;</p>
      <p>{transcript}</p>
      <SubmitButton handleClick={handleClick} />
    </div>
        </>
    )
}
