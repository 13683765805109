import React , {useEffect, useContext, useRef, useState} from 'react'
import { SocketContext } from './App'
import { getStudentsInClass } from './services/list'

export function RecordViewTeacher({class_id}) {
  const socket = useContext(SocketContext)
  const [audioPaths, setAudioPaths] = useState([])

  const mounted = useRef(true);

useEffect(() => {
  mounted.current = true;
  getStudentsInClass(class_id)
  .then((response) => {
    if(mounted.current) {
      const temp = []
      response.data.users.forEach(user => (
        temp.push({user_name: user.user_name, path: '', recordingReceived: false})
      ))
      setAudioPaths(temp)
    }
  })
  .catch(error => {
      console.log(error)
  });
  return () => mounted.current = false;
},[class_id, setAudioPaths])

const useRefs = () => {
  const refsByKey = useRef({})

  const setRef = (element, key) => {
    refsByKey.current[key] = element;
  }
  return {refsByKey: refsByKey.current, setRef};
}

const {refsByKey, setRef} = useRefs()

const refs = Object.values(refsByKey).filter(Boolean) // your array of refs here

useEffect(() => {
  socket.on('s3_received_recording', arg => {
     // console.log("recording received.... ", arg) //username: ..., path: ....
     console.log("XXXX refs ", refs)
          const found = refs.find((element) => element.id ===  arg.username);
          found.src = arg.path       
      
      //replace an object in state array. Compare with the code for adding a new object to a state array and learn
      setAudioPaths(audioPaths.map((item,i)=>{
        var returnValue = {...item};
        if(item.user_name === arg.username){
          returnValue.recordingReceived = true
        }
        return returnValue;
      }))

      /* keep this for future reference. Add a new object to a state Array
        setAudioPaths(audioPaths.map((item,i)=>{
        console.log("XXXXXXXXXXXXXXXXXX item =", item)
        if(item.user_name === arg.username){
          return {...item, path: arg.path};
        }
        return item;
      }))
      */
  })
  //})
  return () => {
      socket.off("s3_received_recording")
  }   
  //eslint-disable-next-line 
}, [socket, refs])

const playAudio = (student_name) => {
  const found = refs.find((element) => element.id === student_name);
  var playPromise = found.load();
  if (playPromise !== undefined) {
    playPromise.then( () => {
      found.play();
      setAudioPaths(audioPaths.map((item,i)=>{
        var returnValue = {...item};
        if(item.user_name === student_name){
          returnValue.recordingReceived = false
        }
        return returnValue;
      }))
    })
    .catch(error => {
    });
  }
}
    return (
      <>
     <div>
    {audioPaths.map(item => (
      <>
        <audio key={item.user_name} id={item.user_name} ref={element => setRef(element, item.user_name)} controls />
        <div><button onClick={() => playAudio(item.user_name)}>&nbsp; {item.user_name} &nbsp;</button>&nbsp;&nbsp;
        { item.recordingReceived ?
          <span className='text-blue-900 text-lg'>
             {item.recordingReceived.toString()}
           </span>
          :
            <span className='text-orange-500 text-lg'>
            {item.recordingReceived.toString()}
        </span>
        }
       
        </div>
        </>
      )) }
    </div>
      
      </>
  )
}
