import React from 'react'
import {  useSelector } from 'react-redux'
import { Button } from 'flowbite-react'

  function SubmitButtonRecordQA({sendblob}) {
 
    //const dispatch = useDispatch()
    let user_answer =  useSelector((state) => state.answer.value)
    
    const process_question_attempt = async () => {

            user_answer= []
    
    }

  return (
    <>
    <div>&nbsp;</div>
    <Button  onClick={() => process_question_attempt()}>Submit</Button>
    </>
  )
}

export default SubmitButtonRecordQA