import React, {useEffect, useRef, useContext, useState} from 'react'
import { setAudioEnded } from "../redux/audio_ended";
import { useDispatch } from "react-redux";
import AudioPlayer from './AudioPlayer.js';
import { PollyContext } from './App.js';
import { AzureSpeechContext } from './App'
import { SpeechSynthesizer, AudioConfig, 
  SpeechSynthesisOutputFormat,
  SpeakerAudioDestination,
 } from 'microsoft-cognitiveservices-speech-sdk';

const wordStyle = {
    fontSize: "18px",
    color: "blue", 
    backgroundColor:"yellow",
    borderRadius:"5px",
    textDecorationSkipInk: 'none',
    padding: "3px",
    margin: '20px 0px 30px 30px',
    border: "solid", 
    borderColor: "#c9cca3",
    userSelect: 'none'
};

export function TextCardAzure({card, handleChoice, card_side, audio_ended}) {
  
    const dispatch = useDispatch()
    //const [audioFile, setAudioFile] = useState('')
    const [side, setSide] = useState('')
    //const [audioEnded, setAudioEnded] = useState(false)
    //const audioRef = useRef()
    //const [audioEnded, setAudioEnded] = useState(false)
    //const polly = useContext(PollyContext);
    //audioEnded flag is not being used now, but you may need it in the future. Kevin
    const azureSpeechConfig = useContext(AzureSpeechContext)

    useEffect(() => {
        setSide(card_side)
    }, [card_side])

    const set_audio_ended = () => {
        console.log("here card side", side)
        if (side === 'right') {
            console.log("right audio ended")
            //setAudioEnded(true)
           //dispatch(setAudioEnded(true) )
           audio_ended()
        }
    }

    const handleClick = (target) => {
            target.style.borderColor = "red"
            setTimeout(() => {
                target.style.borderColor = "#c9cca3"
            }, [700])
            if (card.language === 'en') {
                    azureSpeechConfig.speechSynthesisVoiceName = "en-US-JasonNeural"
                    //azureSpeechConfig.speechSynthesisLanguage = CONFIG.voiceLan;
                    azureSpeechConfig.speechSynthesisOutputFormat = SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3
                    
                    let player = new SpeakerAudioDestination()
                    player.onAudioEnd = function() {
                        //console.log("audio ended")
                      set_audio_ended()
                      //setTimeout(function(){ $("svg path :first-child").each( function(i) {this.beginElement();}); }, 0.5);
                    }
                    const audioConfig = AudioConfig.fromSpeakerOutput(player);
                    //speech_config = speechsdk.SpeechConfig(subscription=speech_key, region=service_region)  
                    //azureSpeechConfig.set_speech_synthesis_output_format(speechsdk.SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3)  
                    const synthesizer = new SpeechSynthesizer(azureSpeechConfig, audioConfig);
                   
                    const complete_cb = function (result) {
                      /*
                      if (result.reason === ResultReason.SynthesizingAudioCompleted) {
                        console.log("synthesis finished");
                      } else if (result.reason === ResultReason.Canceled) {
                        console.log("synthesis failed. Error detail: " + result.errorDetails);
                      }
                      */
                      synthesizer.close();
                      synthesizer = undefined;
                    };
                    const err_cb = function (err) {
                      //window.console.log(err);
                      synthesizer.close();
                      synthesizer = undefined;
                    };
                    synthesizer.speakTextAsync(card.src,
                      complete_cb,
                      err_cb);
            }
            handleChoice(card)
    }

    if (!card.matched ) {
        return (
            <>
            <span style={wordStyle} onClick={(e) => handleClick(e.target)}>
                {card.src}
            </span>
          
            </>
        )
    }
    return (
        <>
            <span style={{color:"red"}}>
                &nbsp;
            </span>
       
        </>
    )
}


/*
 return (
        <>
         { (card.matched === false) ?
            <span style={wordStyle} onClick={(e) => handleClick(e.target)}>
                {card.src}
            </span>
            :
            <span style={{color:"red"}}>
                &nbsp;
            </span>
            }
       <AudioPlayer audioFile={audioFile} setAudioEnded={setAudioEnded} ref={audioRef} />
      
        </>
    )
*/