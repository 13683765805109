import React from 'react'
import { Dropdown } from "flowbite-react";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function TailWindDropDown({category_name, sub_categories}) {
    const user = useSelector(state => state.user.value)
    return (
        <>
      
        <Dropdown
          arrowIcon={true}
          inline
          label={category_name}
        >
        {sub_categories.map(subcat => 
      
        <span key={subcat.id}>
             {(subcat.level.indexOf(user.level) >= 0) &&
               <Dropdown.Item  as={Link} to={`/sub_categories/${subcat.id}`}  key={subcat.id}>
               {subcat.name}
               </Dropdown.Item>
            }
        </span>
      
    )}
        </Dropdown>
        </>
    )
}

/*
 return (
        
        <Dropdown
          arrowIcon={true}
          inline
          label={category_name}
        >
        {sub_categories.map(subcat => 
         {(subcat.level.indexOf(user.level) >= 0) &&
        <Dropdown.Item  as={Link} to={`/sub_categories/${subcat.id}`}  key={subcat.id}>
          {subcat.name}
        </Dropdown.Item>
         }
    )}
        </Dropdown>
        
    )
*/