import React, {useEffect, useRef, useContext,  useState} from 'react'
import { SocketContext } from './App.js';
//import ScoreRow from './ScoreRow';
import {getStudentsInClass} from './services/list.js'
import { useSelector } from 'react-redux';

 function LiveScoreBoard({class_id, question_number}) {
  const socket = useContext(SocketContext);
    const [students, setStudents] = useState([])
    const user = useSelector(state => state.user.value)
    const mounted = useRef(true);

    useEffect(() => {
      mounted.current = true;
      getStudentsInClass(class_id)
      .then((response) => {
        if(mounted.current) {
          setStudents(response.data.users)
        }
      })
      .catch(error => {
          console.log(error)
      });
      return () => mounted.current = false;
    },[class_id])

    useEffect(() => {
      socket.on('next_question_fetched', (arg) => {
        let student_el = document.getElementById(arg.user_name)
        let question_nunber_el = student_el.getElementsByClassName('question_number')[0]
        question_nunber_el.innerHTML = arg.question_number.toString()
        let score_el = student_el.getElementsByClassName('score')[0]
        score_el.innerHTML = ''
        if (user.role === 'teacher') {
          let next_button_enable_acknowledge_el = student_el.getElementsByClassName('next_button_enable_acknowledge')[0]
          next_button_enable_acknowledge_el.innerHTML = '...'
          let user_answer_el = student_el.getElementsByClassName('user_answer')[0]
          if (user_answer_el) {
            user_answer_el.innerHTML = ''
          }
        }
      })
      return () => {
          socket.off("next_question_fetched")
      }   
  },[socket])

    useEffect(() => {
      socket.on('live_score', arg => {
        console.log('live score arg', arg)
          let student_el = document.getElementById(arg.user_name)
          let score_el = student_el.getElementsByClassName('score')[0]
          score_el.innerHTML = arg.score.toString()
          let total_score_el = student_el.getElementsByClassName('total_score')[0]
          if (total_score_el.innerHTML.length === 0) {
            total_score_el.innerHTML = arg.score.toString()
          }
          else {
            let total_score_int = parseInt(total_score_el.innerHTML)
            total_score_int = total_score_int + arg.score
            total_score_el.innerHTML = total_score_int.toString()
          }
          let user_answer_el = student_el.getElementsByClassName('user_answer')[0]
          if (user_answer_el) {
            user_answer_el.innerHTML = arg.user_answer
          }
      })
      return () => {
          socket.off("live_score")
      }   
  }, [socket])

useEffect(() => {
  if (user.role === "teacher") {
      socket.on('enable_next_button_acknowledgement', arg => {
          let student_el = document.getElementById(arg.user_name)
          let next_button_enable_acknowledge_el = student_el.getElementsByClassName('next_button_enable_acknowledge')[0]
          next_button_enable_acknowledge_el.innerHTML = 'X'
      })
      return () => {
            socket.off("enable_next_button_acknowledgement")
      }
  }
}, [socket, user])


  return ( 
        <>
        <div>
        { students.map((student, index) => {
            return <div key={index} id={student.user_name}>
              <span className='mx-1 name'>{student.user_name}</span>
              <span className='mx-2 text-red-500 question_number' ></span>
              <span className='mx-2 score'></span>
              <span className='mx-2 total_score' ></span>
              { user.role === "teacher" &&
              <>
              <span className='mx-1 user_answer' ></span>
              <span className='mx-1 text-green-600 next_button_enable_acknowledge' >...</span>
              </>
              }
            </div>
        })
      }
        </div>
     </>
  );
}

export default LiveScoreBoard
