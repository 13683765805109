import React, { useEffect, useState } from 'react'
import { ScoreDisplay } from './ScoreDisplay'
import { useSelector } from 'react-redux'
import clsx from 'clsx';
import LiveScoreBoard from './LiveScoreBoard';

/*
import clsx from 'clsx';
function MyComponent({ isActive }) {
 return <div className={clsx('base-class', { 'active-class': isActive })}>Hello, world!</div>;
}
*/

function QuestionResponse({ question, response_content }) {
  const livequizflag = useSelector(state => state.livequizflag.value)
  const user = useSelector(state => state.user.value)
  const [errorFlag, setErrorFlag] = useState(false)
  const elapsed_time = useSelector(state => state.elapsed_time.value)
  const [minutes, setMinutes] = useState(null)
  const [seconds, setSeconds] = useState(null)

  useEffect(() => {
    setErrorFlag(response_content.question_attempt_results.error_flag)
  }, [response_content])

  function formatClozeQuestion() {
    //var regExp = /\[.*?\]/g
    var content_with_new_lines = question.content.replace(/#/g, ' ')
    //replace bracket contents with '__________'
    var temp_sentence_with_stars = content_with_new_lines.replace(/ *\[[^\]]*]/g, ' ______ ');
    return <div>{temp_sentence_with_stars}</div>
  }

  function shuffle(array) {
    //("In shuffle .....array", array)
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
      [array[i], array[j]] = [array[j], array[i]];
    }
    //console.log("In shuffle ..............array1", array)

    return (
      <>
        {array.map((item) => (
          //console.log("BBBBBBBBBBB"+item)
          <span className='bg-orange-400 p-2 mx-2'>{item}</span>
        ))}
      </>
    )
  }

  function displayQuestionContent() {
    switch (question.format) {
      case 1:
        //cloze
        return (
          <>
            <pre>{formatClozeQuestion()} </pre>
          </>
        )
      case 2:
          //ButtonSelect
          return (
            <>
              <div>
                {question.content}
              </div>
            </>
          )
      case 3:
        //ButtonSelect
        return (
          <>
            <div>
              {question.content}
            </div>
          </>
        )
      case 4:
        //Radio
        return (
          <>

            <div>
              {question.content}
            </div>
          </>
        )
      case 6:
        //WordScramble
        return (
          <>
            <div>
              {
                shuffle(question.content.split('/'))
              }
            </div>
          </>
        )
      case 8:
        //WordsSelect
        return (
          <>
            <div>
              {question.content}
            </div>
          </>
        )
      case 9:
        return <div>{question.prompt}</div>
      default:
        return null
    }
  }

  function displayResponse() {
    if (response_content.question_format === 6) {
      //console.log("EEEEE", response_content)
      let marr = response_content.question_attempt_results.answer.split('/')
      //words_scramble_direction
      if (response_content.words_scramble_direction === 'y')
        return (
          <ul>
            {marr.map((item, index) =>
            (<li key={index}>
              {item}
            </li>
            )
            )}
          </ul>
        )
      else
        return (
          <div>
            {marr.map((item, index) =>
            (<span key={index}>
              {item} &nbsp;
            </span>
            )
            )}
          </div>
        )
    }
    return (
      <>
        <div>
          {response_content.question_attempt_results.answer}
        </div>
      </>
    )
  }

  function displayCorrectAnswer() {

    if (response_content.question_format === 6) {
      let marr = response_content.question_attempt_results.correct_answer.split('/')
      //words_scramble_direction
      if (response_content.words_scramble_direction === 'y')
        return (
          <ul>
            {marr.map((item, index) =>
            (<li key={index}>
              {item}
            </li>
            )
            )}
          </ul>
        )
      else
        return (
          <div>
            {marr.map((item, index) =>
            (<span key={index}>
              {item} &nbsp;
            </span>
            )
            )}
          </div>
        )
    }
    else if (response_content.question_format === 7) {
      const parts = question.answer_key.split('*')
      return (
        <div style={{ color: 'blue' }}>
          {parts.map((item, index) =>
          (<span key={index}>
            {parseInt(index) + 1}) {item} <br />
          </span>
          )
          )}
        </div>
      )
    }
    else {
      //window.speechSynthesis.speak(msg)
      return (
        <>
          <div>{response_content.question_attempt_results.correct_answer}</div>
        </>
      )
    }

  }

  // <div className={clsx('grid grid-col-span-9', { 'grid grid-col-span-12': livequizflag})}>
  //<div className={clsx('grid grid-cols-12 gap-1 bg-blue-500', { 'bg-red-400 grid grid-cols-9 w-full gap-1': livequizflag})}>
  useEffect(() => {
    const minutes = Math.floor(elapsed_time / 60);
    setMinutes(minutes)
    const seconds = elapsed_time - minutes * 60;
    setSeconds(seconds)
  }, [elapsed_time])
  //
  return (
    <>
      
      <div className="grid grid-cols-12 gap-1">
        <div className={clsx('col-span-9', { 'col-span-12': livequizflag})}>
          <div className='flex flex-col m-2'>
            <div className='flex bg-cyan-200'>
              <div >
                <div>Question {response_content.question_number}</div>
                {(response_content.question_format !== 6) &&
                  <>
                    <div>The question is (Câu hỏi là) :</div>
                    <div>{question.prompt}</div>
                    <br />
                    <pre>{displayQuestionContent()} </pre>
                  </>
                }
              </div>
            </div>
            <div className='bg-orange-300'>
              <div className='flex flex-row flex-wrap'>
                <div>Your answer is (Bạn trả lời là):</div>
                <div className='w-full'><pre className='mt-2 bg-orange-200'>{displayResponse()} </pre></div>

              </div>
            </div>
            {response_content.question_attempt_results.error_flag &&
              <div className='bg-cyan-300'>
                <div>
                  <div>The correct answer is (Câu trả lời đúng là):</div>
                  <pre className='bg-cyan-200 mt-2'>{displayCorrectAnswer(response_content)} </pre>
                  <div>{question.help1}</div>
                </div>
              </div>
            }
          </div>
        </div>
       
          {!livequizflag &&
           <div className="bg-cyan-200 col-span-3">
            <ScoreDisplay errorFlag={errorFlag}
              total_score={response_content.accumulated_score}
              minutes={minutes}
              seconds={seconds}
            />
                </div>
          }
    
      </div>
    </>
  )
}

export default QuestionResponse
//<LiveScoreBoard class_id={user.classId} question_number = {response_content.question_number} />