import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import MatchGameLiveMemory from './MatchGameLiveMemory.js';
import {SocketContext}  from './App.js';


export function HomeKids({categories}) {
    const user = useSelector(state => state.user.value)
    const [myCategories, setMyCategories] = useState([])
    const [gameId, setGameId] = useState(null)
    const [showMatchGame, setShowMatchGame] = useState(false)
    const socket = useContext(SocketContext);
  
useEffect(() => {
  const mcats = categories.filter((category) => {
      //const levels = category.level.split(',')
      //const found = levels.find((level) => level.trim() === user.level);
      //const found = (category.level.indexOf(user.level) >= 0);
      //if (found) {
       return (category.level.indexOf(user.level) >= 0)
      //}
  })
  setMyCategories(mcats)
},[categories, user.level])


useEffect(() => {
  //register "socket.on" event upon component mount
  //or when one of the dependencies changes value
  socket.on('enable_game', (arg) => {
      console.log("enabl game id=",arg.game_id)
      setGameId(arg.game_id)
      //dispatch(setLiveQuizFlag(false) )
      setShowMatchGame(true)
      //setShowMessage(false)
  })
  return () => {
    socket.off("enable_game")
  }  

},[socket])//  
    return (
        <div className='bg-cyan-300 m-12 rounded-lg p-3 text-2xl'>
        {user.full_name}&nbsp;
        <Link to="/logout" className='text-amber-700'>Log out</Link>
         <ul className='flex flex-row justify-evenly'>
           { myCategories.map((category, index) => (
             <li  key={index} >
                <span className='text-amber-700 text-3xl'>{category.name}</span>
                <ul>
                    { category.sub_categories.map((sub_cat, i) => (
                          <li key={i}>
                            {(sub_cat.level.indexOf(user.level) >= 0) &&
                            <span className='underline'><Link to={`/sub_categories/${sub_cat.id}`} >{sub_cat.name}
                            </Link>
                            </span> 
                            }
                          </li>
                    ))
                    }           
                </ul>
             </li>
           ))
           }
         </ul>
         <div>{showMatchGame && <MatchGameLiveMemory gameId={gameId} />}</div>
 
         </div>
      )
}

/*
  return (
        <div className='m-12'>
        {user.full_name} {user.level}
        <Link to="/logout" style={{color:"brown"}}>Log out</Link>
         <ul>
           { myCategories.map((category, index) => (
             <li  key={index} >
                <span className='text-amber-700 text-lg'>{category.name}({category.level })</span>
                <ul>
                    { category.sub_categories.map((sub_cat, i) => (
                          <li key={i}>
                            <span className='underline'><Link to={`/sub_categories/${sub_cat.id}`} >{sub_cat.name}</Link></span>
                          </li>
                    ))
                      
                    }           
                </ul>
             </li>
           ))
           }
         </ul>
         </div>
      )
*/