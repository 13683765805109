import React, { useState, useEffect, useRef } from 'react'
import styles from './MatchGame.module.css';
import TextCardForShuffle from './TextCardForShuffle';
import { useImageSize } from 'react-image-size';
import { playLiveGame } from './services/list';
import ImageCardForShuffle from './ImageCardForShuffle';
import { Button } from 'flowbite-react';
import Counter from './Counter';
//import { m } from 'framer-motion';
import ReactPlayer from 'react-player'

function MatchGameLiveMemory({ gameId }) {
  //shuffledImages

  const [cards, setCards] = useState([])
  const [turns, setTurns] = useState(0)
  const [choiceOne, setChoiceOne] = useState(null)
  const [choiceTwo, setChoiceTwo] = useState(null)
  const [cardGroup1, setCardGroup1] = useState([])
  const [cardGroup2, setCardGroup2] = useState([])
  const [dimensions, { loading, error }] = useImageSize('https://kevinphambucket.s3.amazonaws.com/images/s/star_background.jpeg');
  const [gameName, setGameName] = useState('')
  const [matchCount, setMatchCount] = useState(0)

  const counterRef = useRef()
  const [showCounter, setShowCounter] = useState(false)
  const [gameStarted, setGameStarted] = useState(false)

  const [playing, setPlaying] = useState(true);
  const [showVideo, setShowVideo] = useState(false)
  const [showGameGrid, setShowGameGrid] = useState(false)

  const flipTimeout = useRef()
  const showGameGridTimeout = useRef()
  const setShowVideoTimeout = useRef()
  const handleVideoStopTimeout = useRef()

  const [endingVideoUrl, setEndingVideoUrl] = useState()
  const [videoDuration, setVideoDuration] = useState()

  useEffect(() => {
    // Unexpected string concatenation of literals 
    //console.log("QQQQQQQQQQQQQQQQXXXXXXXXXXX MatchGame gameId"+gameId)
    playLiveGame(gameId)
      .then(response => {
        //console.log("XXXXXX YYYY", response.data)
        setEndingVideoUrl(response.data.video_url)
        setVideoDuration(response.data.video_duration)
        setGameName(response.data.name)
        let myArray1 = response.data.base.split('/').map((str, index) => {
          return (
            { src: str.trim(), matched: false, match_index: index, has_image: false, language: response.data.source_language }
          )
        });
        //console.log("myArray1 = ", myArray1)
        setCardGroup1(myArray1)
        let myArray2 = response.data.target.split('/').map((str, index) => {
          let initial = str[0]
          let mystr2 = "https://kevinphambucket.s3.amazonaws.com/images/" + initial + '/' + str
          return (
            { src: mystr2, matched: false, match_index: index, has_image: true, language: response.data.target_language }
          )
        });
        setCardGroup2(myArray2)
        //console.log("myArray1 = ", myArray1)
      })
  }, [gameId])

  //setAge(a => a + 1)

  const shuffleCards = () => {
    //console.log("in shuffleCards cardGroup1=",cardGroup1)
    //console.log("in shuffleCards cardGroup2=",cardGroup2)
    const shuffleCards = [...cardGroup1, ...cardGroup2]
      .sort(() => Math.random() - 0.5)
      .map((card, index) => ({ ...card, id: Math.random() }))
    //console.log(shuffleCards)

    setCards(shuffleCards)
    setTurns(0)
    setShowCounter(true)
    setGameStarted(true)
    setShowGameGrid(true)
    setMatchCount(0)
    setPlaying(true);

    //clearGamesBoard()
  }

  const handleChoice = (card) => {
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card)
  }

  const handleVideoStop = () => {
    setPlaying(false);
    setShowVideo(false)
    setShowCounter(false)
  };


  const resetTurn = () => {
    setChoiceOne(null)
    setChoiceTwo(null)
    setTurns(prevTurn => prevTurn + 1)
  }
  useEffect(() => {
    if (choiceOne && choiceTwo) {
      //if (choiceOne.src === choiceTwo.src) {
      if (choiceOne.match_index === choiceTwo.match_index) {
        setMatchCount((previous => previous + 1))
        setCards(prevCards => {
          return prevCards.map(card => {
            if (card.match_index === choiceOne.match_index) {
              //console.log("match!")

              return { ...card, matched: true }
            }
            else {
              return card
            }
          })
        })
        resetTurn()
      }
      else {
        //console.log("NO MATCH")
        flipTimeout.current = setTimeout(() => resetTurn(), 1000)
        //setTimeout(() => resetTurn(), 1000)
      }
    }
    return () => {
      clearTimeout(flipTimeout.current)
    } 
  }, [choiceOne, choiceTwo])

  useEffect(() => {
    if (matchCount === 8) {
      //console.log("Game over!")
      counterRef.current.clearCount()
      setGameStarted(false)
      setShowVideoTimeout.current = setTimeout(() => setShowVideo(true), 2000)
      //setShowVideo(true)
      //setShowGameGrid(false)
      showGameGridTimeout.current = setTimeout(() => setShowGameGrid(false), 1000)
      handleVideoStopTimeout.current =setTimeout(() => handleVideoStop(), videoDuration)
    }
    return () => {
      clearTimeout(showGameGridTimeout.current)
      clearTimeout(setShowVideoTimeout.current)
      clearTimeout(handleVideoStopTimeout.current)
    } 
  }, [matchCount])

  return (
    <div className='MathGame'>
      <div className='text-amber-700 text-4xl flex flex-row justify-center mt-5'>{gameName}</div>
      {!gameStarted && <div><Button onClick={shuffleCards}>Start Game</Button></div>}
      {showCounter && <span className='mx-11' my-1><Counter ref={counterRef} /></span>}
      {showVideo && <ReactPlayer playing={playing} url={endingVideoUrl} />}
     
      { showGameGrid && 
      <div className={styles.cardgrid} >
        {cards.map(card => {
          if (card.has_image)
            return (
              <ImageCardForShuffle
                key={card.id}
                card={card}
                width={dimensions?.width}
                height={dimensions?.height}
                handleChoice={handleChoice}
                flipped={card === choiceOne || card === choiceTwo || card.matched}
              />
            )
          else
            return (
              <TextCardForShuffle
                key={card.id}
                text={card}
                width={dimensions?.width}
                height={dimensions?.height}
                handleChoice={handleChoice}
                flipped={card === choiceOne || card === choiceTwo || card.matched}
              />
            )
        })}
      </div>
      } 
    </div>
  )
}

export default MatchGameLiveMemory

/*
 useEffect( () => {
      // Unexpected string concatenation of literals 
      //console.log("QQQQQQQQQQQQQQQQXXXXXXXXXXX MatchGame gameId"+gameId)
      playLiveGame(gameId)
      .then (response => {
        setGameName(response.data.name)
        let myArray1 = response.data.base.split('/').map((str, index) => {
            let mystr1
            if (response.data.source_has_images) {
              let initial = str[0]
              mystr1 = "https://kevinphambucket.s3.amazonaws.com/images/" + initial + '/' + str + '.jpeg'
            }
            else {
              mystr1 = str
            }
            return (
              {src: mystr1, matched: false, match_index: index, has_image: response.data.source_has_images, language: response.data.source_language}
            )
        });
        setCardGroup1(myArray1)
        let myArray2 = response.data.target.split('/').map((str, index) => {
            let mystr2
            if (response.data.target_has_images) {
              let initial = str[0]
              mystr2 = "https://kevinphambucket.s3.amazonaws.com/images/" + initial + '/' + str + '.jpeg'
            }
            else {
              mystr2 = str
            }
            return (
              {src: mystr2, matched: false, match_index: index, has_image: response.data.target_has_images, language: response.data.target_language }
            )
        });
        setCardGroup2(myArray2)
      })
    },[gameId])
*/