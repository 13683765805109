import React from 'react'

export default function CEditor(props) {
  

  return (
    <>
      In CEDITOR
    </>
  )
}
