import React, { useState, useEffect } from 'react'
import SubmitButton from './SubmitButton'
import { SoundButtonAzure } from './SoundButtonAzure';
import format_button_cloze_question_content from './formatButtonClozeQuestion'

function ButtonSelectClozeQuestionAttempt({ question, setUserAnswer }) {
  const [selectedItem, setSelectedItem] = useState(null)
  const [leftPart, setLeftPart] = useState(null)
  const [rightPart, setRightPart] = useState(null)
  const [labels, setLabels] = useState([])

  //const fillTextRef = useRef()

  const set_selected_item = (item) => {
    setSelectedItem(item)
    //console.log("MMMM select item", item)
    //console.log(" id =", fillTextRef.current.id)
    const fill_el = document.getElementById("fill")
    fill_el.innerText = item
  }

  const handleSubmitClick = () => {
    if (selectedItem == null) {
      alert("Please selected an option")
    }
    else {
      //call function from parent component (LiveQuestionAttempt) to set user answer
      console.log("MMMMMM selectedItem", selectedItem)
      setUserAnswer(selectedItem)
    }
  }


  useEffect(() => {
    //console.log(" in useEffect quesiton=",question)
    let { left, right, words } = format_button_cloze_question_content(question.content)
    console.log("OOOOO", left)
    setLeftPart(left)
    setRightPart(right)
    setLabels(words.split('/'))
    //setLeftAndRightSentences(format_button_cloze_question_content(question))
  }, [question])

  return (
    <>
      <div className='flex flex-row justify-center'>
        <div className='mx-2'>{leftPart && leftPart}</div>
        <div className='text-amber-700' id='fill'>_______</div>
        <div className='mx-2'>{rightPart && rightPart}</div>
      </div>

      <div>
        <ul className='flex flex-1 gap-3'>
          {labels.map(label =>
            <SoundButtonAzure key={label} label={label}
              parent_setSelectedItem={set_selected_item} />
          )}
        </ul>
        <div style={{ width: "60px" }}>
          {
            <SubmitButton handleClick={handleSubmitClick} />
          }
        </div>
      </div>
    </>
  )
}

export default ButtonSelectClozeQuestionAttempt
//   <Button className='bg-indigo-500' key={item} onClick={() => handleChoiceClick(item)}>{item}</Button>

/*
return (
    <> 
    <div className='flex flex-row justify-center gap-2'>
    <div>There is</div>
      <span id="fill"><span className='text-gray-400'>_________</span></span>
    <div>in the picture.</div>
    </div>

    <br />
    <ul className='flex flex-row justify-center gap-3'>
      {labels.map(label => 
            <SoundButtonAzure key={label} label = {label} 
            parent_setSelectedItem={set_selected_item} />
      )}
      </ul>
      <div style={{width:"60px"}}>
      {
            <SubmitButton handleClick={handleSubmitClick} />
        }
    </div>
    </>
  )
*/