import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


export default function UnitTeacher({unit}) {
  
  const livequizflag = useSelector(state => state.livequizflag.value)
  const livequizid = useSelector(state => state.livequizid.value)
  const user = useSelector(state => state.user.value)
  const navigate = useNavigate()

  //http://localhost:3000/quizzes/manage_questions/125
  const toQuestionsManager = (quiz_id) => {
    navigate(`/quizzes/manage_questions/${quiz_id}`, { 
      state: { subcat_id: unit.subCategoryId},
      replace: false,
  })
  } 
  
        if (livequizflag) {
        return (
          <>
        <div className="text-amber-700 text-lg">Unit{unit.unit_number}.&nbsp;&nbsp;{unit.name}</div>
        <div>
        {unit.quizzes && 
        <ul>
        {unit.quizzes.map((quiz) =>  
              (<li key = {quiz.id}>
                Quiz {quiz.quiz_number} &nbsp;
                { quiz.id === parseInt(livequizid) ?
                <Link to={`/quiz_attempts/take_live_quiz/${quiz.id}`}
                  className="font-serif text-normal text-blue-800 dark:text-blue-500 hover:underline"
                  >
                  {quiz.name}</Link>
                  :
                  <span className="text-gray-400">{quiz.name}</span>
                }
              </li> 
              )
          )}
      </ul>}
        
        </div>
        </>
        )
  }
   else {
      return(
        <>
        <div className="text-amber-700 text-lg">Unit{unit.unit_number}.&nbsp;&nbsp;{unit.name}
        { user.role === 'teacher' && 
          <span>
             <Link to={`/units/manage_quizzes/${unit.id}`}>Manage Quizzes</Link>
          </span>
        }
        </div>
        <div>
        {unit.quizzes && <ul>
        {unit.quizzes.map((quiz) =>  
              (<li key = {quiz.id}>
                Quiz {quiz.quiz_number} &nbsp;
  
                <Link to={`/quiz_attempts/take_quiz/${quiz.id}`}
                  className="font-normal text-green-800 dark:text-blue-500 hover:underline"
                  >
                  {quiz.name}</Link>
                 
                    <span style={{color:"red"}}>&nbsp;&nbsp;&nbsp; {quiz.id}</span>
                    <button className='underline text-red-800' onClick={ (quiz_id) => toQuestionsManager(quiz.id)} >
                  Manage Questions
                </button>
              </li> 
              
              )
          )}
      </ul>}
        
        </div>
        </>
    )
    }
 
  }
  
/*
  <table >
            <thead>
                <tr>
                   <th>ID</th>
                   <th>Quiz Number</th>
                   <th>Quiz Name</th>
                </tr>
              </thead>
            <tbody>
                 {unit.quizzes.map((quiz) =>  
              (<tr key = {quiz.id}>
                <td>{quiz.id}</td>
                <td>{quiz.quiz_number}</td>
                <td>Name: {quiz.name}</td>
                <td><button className='underline text-red-800' onClick={ (quiz_id) => toQuestionsManager(quiz.id)} >
                  Manage Questions
                </button>
                </td>
              </tr> 
              )
          )}
            </tbody>
  </table>
*/