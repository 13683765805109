import React, {useState, useEffect} from 'react'
import { HomeStudent } from './HomeStudent.js';
import { HomeTeacher } from './HomeTeacher.js';
import { useSelector } from 'react-redux';
import { ConnectionState } from './ConnectionState';
import NavBarTailWind from './NavBarTailwind.js';
import { setLiveQuizFlag } from '../redux/livequizflag.js';
import { useDispatch } from 'react-redux';
import { setLiveQuizId } from '../redux/livequizid.js';
import { clearLiveQuizId } from '../redux/livequizid.js';
import { Link } from 'react-router-dom';
import { Button, TextInput } from "flowbite-react";
import ChatPageTailwind from './chat/ChatPageTailwind.js';
import { getAQuiz, getCategories } from './services/list.js';
import { HomeKids } from './HomeKids.js';

function Home({socket}) {
  const user = useSelector( state => state.user.value )
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [categories, setCategories] = useState([])
  const livequizflag = useSelector(state => state.livequizflag.value)
  const livequizid = useSelector(state => state.livequizid.value)

  const dispatch = useDispatch()
  
//<div><ConnectionState isConnected={ isConnected } /></div>
  useEffect(() => {
    console.log(" second useEffect")
    // no-op if the socket is already connected
    //console.log(" ChatPage connecting to server")
    socket.connect();
    getCategories()
    .then ( response => {
        setCategories(response.data);   
    })
    //
    // comment this out so that when the Home component dismounts, i.e, user
    //    go to another link, socket won't get disconnected.
    //    Leave to code here just for reference/learning
    //return () => {
    //  socket.disconnect();
    //};
    //eslint-disable-next-line
},[]);

const disableLiveQuiz = () => {
  dispatch(setLiveQuizFlag(false))
  dispatch(clearLiveQuizId())
  
}
const enableLiveQuiz = () => {
if (!livequizid) {
  alert("Please ENTER quiz id.")
}
else if (livequizid.length === 0) {
  alert("Please ENTER quiz id.")
}
else {
  getAQuiz(livequizid)
  .then ((response) => {
    dispatch(setLiveQuizFlag(true))
  })
  .catch((error) => {
    alert(`ERROR getting quiz id ${livequizid}`)
  }) 
}
}

  if (user.level === 'beginner') {
    return <HomeKids categories={categories}/>
  }
  return (
    <>
    { categories.length > 0 && 
      <NavBarTailWind categories={categories}/>
    }
       <div className='flex flex-row h-1 mx-10'>Live quiz: 
          <span className='text-red-600 text-lg'>&nbsp;{livequizflag ? "ON" : "OFF"}</span>
           { livequizflag && 
            <div className='bg-gray-50 grid grid-flow-col'>
     
                  <span> &nbsp; Quiz id: {livequizid},</span>&nbsp;
               
                 
                  <Link to={`/quiz_attempts/take_live_quiz/${livequizid}`}
                  className="mx-4 font-serif text-lg text-green-800 dark:text-blue-500 underline"
                  >
                  Go to quiz</Link>
            </div>
                  
            }
            </div>
            <div className="mt-10 flex flex-row h-72 gap-3 bg-gray-200 justify-between">
                <div className="mt-10 flex m-2 mx-10 h-11 flex-row gap-4 ">
                  <Button className='bg-cyan-600 md: w-full px-0' onClick={enableLiveQuiz} >Turn Live Quiz On</Button>
                  <TextInput type='text' value={livequizid} size='auto' 
                    onChange={(e) => dispatch(setLiveQuizId(e.target.value))}/>
                  <Button className='bg-cyan-600 m-0 w-full scroll-px-0' onClick={disableLiveQuiz}>Turn Live Quiz Off</Button>
                  <div className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
                    <Link to='/matching_games' >Games</Link>
                  </div>
                </div>
                <div className='bg-red-300'>
                   <ChatPageTailwind layout = "flex_row" />
                </div>
            </div>
    <div className="flex flex-col mx-0 px-2 bg-gray-200">
    <div className='bg'>
    { user.role === 'teacher' ?
        <HomeTeacher />
        :
        <HomeStudent user = {user} />
        }
       </div>
      

    </div>
    
    

    </>
  )
}

export default Home