import React, {useState, useEffect, useContext} from 'react'
import RecordViewStudent from './RecordViewStudent.js'
import { Button } from "flowbite-react";
import { useDispatch } from 'react-redux';
import {SocketContext}  from './App.js';


export function HomeStudent({user}) {
    const [showRecordView, setShowRecordView] = useState(false)
    const socket = useContext(SocketContext);
    const dispatch = useDispatch()
  
 
    const toggleRecord = () => {
        setShowRecordView(!showRecordView)
      }
  

    return (
        <>
       
          <div className="flex flex-col  my-5 h-80 gap-5 bg-gray-200 rounded-md">
            <div className='bg-cyan-200 mt-6 p-3 px-10' dangerouslySetInnerHTML={{ __html: user.message }}></div>  
            <Button className='w-36 mx-10 bg-amber-600' onClick={toggleRecord}>Show Record</Button>
            {showRecordView && 
            <div className='bg-blue-300 mx-10' >
              <RecordViewStudent />
            </div>
            }
            
        </div>
        </>
    )
}
