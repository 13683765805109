import React, {useContext, useEffect, useCallback, useRef} from 'react'
import { useState } from "react";
import { Reorder } from "framer-motion"
import SubmitButton from './SubmitButton';
import { ImHome, ImPlay } from "react-icons/im";

import { PollyContext } from './App'

const itemStyle =  {
  borderRadius: "5px",
  marginBottom: "5px",
  marginLeft: "10px",
  
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  padding: "10px 10px 10px 10px",
  background: "cyan"
}
function WordsScrambler({question, setUserAnswer}) {

  const useRefs = () => {
    const refsByKey = useRef({})
  
    const setRef = (element, key) => {
      refsByKey.current[key] = element;
    }
    return {refsByKey: refsByKey.current, setRef};
  }
  const {refsByKey, setRef} = useRefs()
  const [items, setItems] = useState([]);
  const [unSortedItems, setUnSortedItems] = useState([])
  const polly = useContext(PollyContext)
  
const handleClick = () => {
  const uanswer = document.getElementsByClassName('word_scrambler_items')
  if (question.words_scramble_direction === 'y') {
  const parser = new DOMParser();
const my_answers_array = []
  for (let i = 0; i < uanswer.length; i++) {
    /* KP. Keep this for reference:  When direction is 'y', 
      uanswer[i].innerHTML looks like this. Need to parse using DOMParser
two<span class="text-red-500">*</span>
<svg ....
class="...
<path ...></path></svg>
    */
    const html_doc = parser.parseFromString(uanswer[i].innerHTML, "text/html")
    my_answers_array.push({id: uanswer[i].id, text:  html_doc.body.firstChild.textContent})
  }
   //my_answers_array => [{"id": "0","text": "one"}, {"id": "2","text": "three"}  ]
  setUserAnswer(my_answers_array)
}
else {  //direction = 'x'
    //console.log("IIIII", uanswer)
    var temp_arr = []
  for (let i = 0; i < uanswer.length; i++) {
    var word = uanswer[i].innerHTML
    temp_arr.push({id: uanswer[i].id, text: word})
  }
  setUserAnswer(temp_arr)
} 
}
 
useEffect(() => {
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
      [array[i], array[j]] = [array[j], array[i]];
    }
  }  
    
    let myArray1 = question.content.split('/').map((str, index) => ({ text: str, id: index }));
    setUnSortedItems([...myArray1])
    
    var listItems = []
    shuffle(myArray1)
    myArray1.forEach( (element, index) => {
        listItems.push({text: element.text, id: element.id})
    });
    //console.log("XXXXXXXXXXXX listItems",listItems)
    setItems(listItems)
   
},[question.content, setItems])

//useCallback ensures that convertTextToSpeech doesn't get called on every render (i.e, when any state variable changes). \
// Just the first time the component renders

  const convertTextToSpeech = useCallback((item) => {
    polly.synthesizeSpeech({
      Engine: "generative",
      LanguageCode: "en-US",
      Text: item.text,
      OutputFormat: 'mp3',
      VoiceId: "Ruth",
    },
    (error, data) => {
        if (error) {
          console.log(error);
        } else {
        const refs = Object.values(refsByKey).filter(Boolean) // your array of refs here
        const audioArrayBuffer = data.AudioStream.buffer;
        const audioURL = URL.createObjectURL(new Blob([audioArrayBuffer], {type: "audio/mpeg" }));
        //const audio = audioRef.current
        refs[item.id].src = audioURL
        return () => {
            URL.revokeObjectURL(audioURL)
        }
        }
    })
  },[polly, refsByKey])

  useEffect(() => {
    unSortedItems.forEach((item) => {
      //console.log("HHHHHHH unsorted item = ", item)
      convertTextToSpeech(item)
    })
    //console.log(" useEffect new item order", items)
},[unSortedItems, convertTextToSpeech])
//
const playAudio = (item ) => {
    //console.log("mmmmm item ", item)
    const refs = Object.values(refsByKey).filter(Boolean) // your array of refs here
    //console.log("OOOOOO", refs)
    refs[item.id].play()
}
const handleClick1= (item) => {
  //console.log("MMMMM", e)
}

    if (question.words_scramble_direction === 'x') {
      return (
        <>
      
      { unSortedItems.map(item => (
        <>
          <audio key={item.id} id={item.id} ref={element => setRef(element, item.text)} />
        </>
        ))
      }
        <Reorder.Group axis = 'x' 
          values={items} 
          onReorder={setItems} 
          style={{display:"flex", flexDirection:"row"}}
          >
          {items.map((item) => (
            // Change the li to Reorder.Item and add value prop
             <Reorder.Item className="word_scrambler_items"  
             key={item.id} 
             onMouseDown={() => playAudio(item)}
             style={ itemStyle}
             id={item.id}
             value={item}
             >{item.text}
             </Reorder.Item>
          ))}
        </Reorder.Group>
        <div style={{width:"60px"}}>
            <SubmitButton handleClick={handleClick} />
         </div>
        </>
      );
    }
    else {
      return (
        <>
          { unSortedItems.map(item => (
        <>
          <audio key={item.id} id={item.id} ref={element => setRef(element, item.text)} />
        </>
        ))
      }
        <Reorder.Group axis = 'y' 
          values={items} 
          onReorder={setItems} 
          >
          {items.map((item) => (
            <Reorder.Item className="word_scrambler_items" 
             key={item.id}
             style={ itemStyle} 
             id={item.id}
             value={item}>
              <div className='flex flex-row justify-start'>
            <span className='mx-2'>{item.text}</span>
            
            <span><ImPlay className='text-blue-500 text-2xl' onClick={(e) => playAudio( item) }/></span>
            </div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
        <div style={{width:"60px"}}>
            <SubmitButton handleClick={handleClick} />
       </div>
    
        </>
      );
    }
   
}

export default WordsScrambler

/*
<Reorder.Item onMouseDown={testClick} className="word_scrambler_items" style={ itemStyle}  key={item.id} value={item}>
              {item.text}
            </Reorder.Item>
*/