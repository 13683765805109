import React, {useState} from "react";
import { Radio, Label} from "flowbite-react";
import SubmitButton from "./SubmitButton";

export const RadioQuestion = ({question, setUserAnswer, live_flag}) => {
  const [selectedItem, setSelectedItem] = useState(null)
 
  const onOptionChange = (e) => {
    //console.log("XXXXXXXXXXXXXXXXXX"+e.target.value)
    //dispatch(setAnswer(e.target.value))
    setSelectedItem(e.target.value)
  }

  const handleClick = () => {
      setUserAnswer(selectedItem)
  }
  /*
<div className="flex items-center gap-2">
        <Radio id="united-state" name="countries" value="USA" defaultChecked />
        <Label htmlFor="united-state">United States</Label>
      </div>
  */
  return (
    <>
    <div>{question.content}</div>
    <br />
    <fieldset>
      {['radio'].map((type) => (
        <div key={`${type}`} className="mb-3">
          <div className="flex items-center gap-2">
          <Radio
            name="radio"
            value="choice1"
            type={type}
            id={`${type}_choice_1`}
            onChange={onOptionChange}
          />
           <Label htmlFor="united-state">{question.radio.choice_1_text}</Label>
          </div>

          <div className="flex items-center gap-2 mt-2">
          <Radio
            label={'  ' + question.radio.choice_2_text}
            name="radio"
            value="choice2"
            type={type}
            id={`${type}_choice_2`}
            onChange={onOptionChange}
          />
           <Label htmlFor="united-state">{question.radio.choice_2_text}</Label>
          </div>

          <div className="flex items-center gap-2 mt-2">
            <Radio
            label={'  ' + question.radio.choice_3_text}
            name="radio"
            value="choice3"
            type={type}
            id={`${type}_choice_3`}
            onChange={onOptionChange}
          />
          <Label htmlFor="united-state">{question.radio.choice_3_text}</Label>
          </div>
          
          {question.radio.choice_4_text && (
            <div className="flex items-center gap-2 mt-2">
          <Radio
            label={'  ' + question.radio.choice_4_text}
            name="radio"
            value="choice4"
            type={type}
            id={`${type}_choice_4`}
            onChange={onOptionChange}
          />
          <Label htmlFor="united-state">{question.radio.choice_4_text}</Label>
          </div>
          )}
        </div>
      ))}
    </fieldset>
    <div>&nbsp;</div>
    <div style={{width:"60px"}}>
            <SubmitButton handleClick={handleClick} />
    </div>
    </>
  );
};