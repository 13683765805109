import React, {useContext} from 'react'
import { Button } from 'flowbite-react'
import { AzureSpeechContext } from './App'
import { SpeechSynthesizer, AudioConfig, 
  SpeechSynthesisOutputFormat,
  SpeakerAudioDestination,
 } from 'microsoft-cognitiveservices-speech-sdk';

export function SoundButtonAzure({label, parent_setSelectedItem}) {
  
    const azureSpeechConfig = useContext(AzureSpeechContext)

    const handleChoiceClick = (value) => {
        parent_setSelectedItem(value)
   
        azureSpeechConfig.speechSynthesisVoiceName = "en-US-JasonNeural"
        //azureSpeechConfig.speechSynthesisLanguage = CONFIG.voiceLan;
        azureSpeechConfig.speechSynthesisOutputFormat = SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3
        
        let player = new SpeakerAudioDestination()
        player.onAudioEnd = function() {
          console.log("playback eeended");
          //setTimeout(function(){ $("svg path :first-child").each( function(i) {this.beginElement();}); }, 0.5);
        }
        const audioConfig = AudioConfig.fromSpeakerOutput(player);
        //speech_config = speechsdk.SpeechConfig(subscription=speech_key, region=service_region)  
        //azureSpeechConfig.set_speech_synthesis_output_format(speechsdk.SpeechSynthesisOutputFormat.Audio16Khz32KBitRateMonoMp3)  
        let synthesizer = new SpeechSynthesizer(azureSpeechConfig, audioConfig);
       
        const complete_cb = function (result) {
          /*
          if (result.reason === ResultReason.SynthesizingAudioCompleted) {
            console.log("synthesis finished");
          } else if (result.reason === ResultReason.Canceled) {
            console.log("synthesis failed. Error detail: " + result.errorDetails);
          }
          */
          synthesizer.close();
          synthesizer = undefined;
        };
        const err_cb = function (err) {
          //window.console.log(err);
          synthesizer.close();
          synthesizer = undefined;
        };
        synthesizer.speakTextAsync(value,
          complete_cb,
          err_cb);
  }
    return (
        <>
            <Button onClick={event => handleChoiceClick(event.target.innerText)}>{label}</Button>
        </>
    )
}
